.inner-banner{ width: 100%; height: 250px; background-color: #000; overflow: hidden; position: relative;}
.inner-banner .title{ position: absolute; left: 8.5%; top:62%;  transform: translateY(-50%); z-index: 1;}
.inner-banner .title h1{ color: #fff;}
.inner-banner img{height:fit-content; object-fit: cover; opacity: .3; width: 100%; object-position: 50% 50%; }
.contact-sec{ padding:6rem 0 ;}
.form-box-c{ background: rgb(234 234 234 / 45%);padding: 30px; border: 1px solid #ccc;}
.form-box-c .mb-3{ position: relative; margin-bottom: 20px!important;;}
.form-box-c .mb-3 .form-alert{ font-size: .8rem; color:#ff0000 ; position: absolute; bottom:-18px;}
.form-box-c .form-control, .form-box-c .form-select{ border-radius: 0px; height: auto; line-height: 2rem;}
.form-box-c .btnform {background-color: #002956; color: #fff;padding: 10px 25px; font-size: 1rem; border-radius: 0px; border: 0px;}
.contact-box li{ position: relative; padding-left: 45px; margin-bottom: 15px; line-height: 2.4rem; color:#333;}
.contact-box li:last-child{ margin-bottom: 0px;}
.contact-box li i{ width: 40px; height: 40px; padding: 10px;border-radius: 25px; border: 1px solid #ccc; position: absolute; left:0px}
.contact-box li span button{ padding: 0; border: 0; background: none; color:#333; font-weight: 200;}
.contact-box li span button:hover{ color:#002956;}
.map-sec{ width: 100%; min-height: 400px;}
.map-sec iframe{ width: 100%;}
@media (max-width: 500px) {
    .inner-banner img{height: 100%;}
    .form-box-c{ margin-top: 20px;}

}
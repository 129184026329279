.banner-sec{ width: 100%; padding:120px 0 60px 0; background: #5daedd;  position: relative;}
.banner-sec .banner-text{padding: 40px; }
.banner-sec .banner-text h1{ color:#002755; font-size: 3rem; font-weight: 600;  margin-bottom: 15px;}
.banner-sec .banner-text p{ font-size: 1.5rem; color: #fff;  margin-bottom: 15px;}
.banner-sec .banner-text .banner-btn{ padding: 10px 25px; color: #fff; transition: 0.3s; font-size:1.2rem; background: #f15a21; text-decoration: none; display: inline-block;}
.banner-sec .banner-text .banner-btn:hover{background:#002755;}
.banner-sec .image-b{ background-image:url(../../public/assets/images/bg-profile.png); background-repeat: no-repeat;     background-position: center; background-size: contain; width: 500px; height: 500px;     padding: 0px 0px 0 0; text-align: center;}
.banner-sec .image-b img{ height: 100%;}
.sec-contant{ width: 100%; padding: 60px 0;}
.title-t{ width: 100%; margin-bottom: 60px;}
.title-t h2{ font-size:2.5rem; color:#002755; margin-bottom: 15px;}
.title-t p{ font-size: 1.2rem; color:#333; margin-bottom: 15px;}
.l-box{ background: #fff;  width: 100%; padding: 20px; box-shadow: 0 0 15px #ccc; border-radius: 10px; margin-bottom: 30px; min-height:197px;}
.l-box .icon-box{margin-bottom: 15px;}
.l-box .icon-box .icon{ color: #38b6ff; font-size: 3rem; }
.l-box h3{ margin-bottom:15px; text-transform: uppercase; font-size: 1.2rem; color: #38b6ff;}
.l-box p{ margin-bottom:0px; font-size: 1.2rem; color: #333; line-height: 1.2rem; font-size: 1rem;}
.mid-sec{padding: 60px 0; width: 100%;}
.contant-b{ width: 100%;}
.contant-b h2{color:#002755; font-size: 1.5rem; font-weight: 600;  margin-bottom: 15px;}
.contant-b p{ font-size:1rem; color: #333; margin-bottom: 15px;}
.contant-b ul{ padding: 15px 0; display:flex;}
.contant-b ul li{ padding: 15px; border-radius: 15px; background: #f4f8fd; margin: 0 10px;}
.pricing-sec{ padding: 60px 0; width: 100%;}
.pricing-sec .title-h h2 { font-size: 1.5rem; color:#002755;margin-bottom: 15px; line-height: 1.8rem;}
.pricing-sec .title-h h2 span{ font-size:1rem;}
.price-box{background: #f4f8fd; padding: 20px; width: 100%; margin-bottom: 20px;}
.price-box ul{ padding: 0px; margin: 0;}
.price-box ul li{ font-size: 1rem; line-height: 1.8rem;}

.price-bx{background: #f4f8fd; padding: 20px; width: 100%; margin-bottom: 20px;}
.price-bx p{ font-weight: 400; color:#000; margin-bottom: 20px; font-size: 1rem;}
.price-bx h3{ font-size: 1.2rem;}
.price-bx ul{ padding: 0px; margin:15px 0;}
.price-bx ul.check-list li{ font-size: 1rem; line-height: 1.8rem; position: relative; padding-left: 20px;}
.price-bx ul.check-list li:before{  content: "\e92a"; font-family: 'icomoon';  position: absolute; top:0px; left:0px;}
.price-bx ul.price-list{padding: 0; margin:25px 0 0;}
.price-bx ul.price-list li{ margin-bottom: 15px; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;}
.price-bx ul.price-list li label{ font-weight: 600;}
.price-bx ul.price-list li .price{ font-size: 1rem; padding:5px 25px; background:#ccc; border: 0px;}
.faq-sec{ padding: 60px 0; width: 100%; background: #f4f8fd;}
.price-bx ul.price-list li:last-child{ margin-bottom: 0px;}

#accordionfaq .accordion-button { font-size: 1.5rem;}
#accordionfaq ol li{ font-size:1.2rem;}
#accordionfaq ol li ul{ list-style-type: disc;}
.sec-box{ width: 100%; padding:8rem 0 5rem; background-image:url(../../public/assets/images/about-bg-top.png); background-repeat:repeat-x; }
.sec-box .rec-img-box{background-image:url(../../public/assets/images/circle4877-2-4-6-1.png); background-repeat:no-repeat; background-size: 20%; background-position: right top; height: 100%; display: flex; align-items: center;}
.sec-box .right-contant{background-image:url(../../public/assets/images/circle4877-2-4-6-1.png); background-repeat:no-repeat; background-size: 30%; background-position: right top; padding:0 3rem;}
.sec-box .right-contant h2{ font-size:1.8rem; color: #002755;}
.sec-box .row{ margin-bottom: 30px;}
.sec-box .row:last-child{margin-bottom:0;}
.sec-box .right-contant p{ font-size: 1.1rem; margin-bottom:15px;}
.sec-box .right-contant.leftside{background-size: 25%;}
.banner-testi{ padding-bottom: 0px;}
.testimonial-sec{ padding: 60px 0;}
@media (max-width:500px) {
    .banner-sec .banner-text{padding:0 10px 10px; text-align: center;}
    .banner-sec .banner-text h1{font-size: 1.8rem;}
    .banner-sec .banner-text p{ font-size: 1.2rem;}
    .review-sec .subtitle{ width: 80%; margin: 0  auto;}
    .container {max-width: 100%;}
    .book-list li{ width: 100%;}
    .banner-sec .image-b{width: 369px; height: 369px; margin: 0px auto;}
    .price-bx ul.price-list li label{ width: 100%; margin-bottom: 10px; text-align: center;}
    }
.inner-banner{ width: 100%; height: 250px; background-color: #000; overflow: hidden; position: relative;}
.inner-banner .title{ position: absolute; left: 8.5%; top:62%;  transform: translateY(-50%); z-index: 1;}
.inner-banner .title h1{ color: #fff;}
.inner-banner img{height:fit-content; object-fit: cover; opacity: .3;}
.about-sec{ padding: 5rem 0; width: 100%;}
.intro-box h2{font-size: 2rem; color:#02043a; margin-bottom:.5rem; }
.intro-box h2 span{ font-size: 1rem; color:#38b6ff;}
.intro-box p{ margin-bottom: .5rem;}
.ser-sec{ padding: 5rem 0; width: 100%; background: rgb(240, 240, 240);}
.title-m{ width: 100%;}
.title-m h2{font-size: 2rem; color:#02043a; margin-bottom:.5rem; text-align: center; }
.title-m h2 span{ font-size: 1rem; color:#38b6ff;}
.timline-bx{ width: 100%; position: relative; height: 100%; margin: 40px 0; padding: 20px 0;}
.timline-bx:before {content: ''; position: absolute; top: 0; left: 50%; margin-left: -1px;width: 2px;height: 100%; background: #CCD1D9; z-index: }
 .timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl
 }
 
 .marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #F5F7FA;
    background: #4FC1E9;
    margin-top: 10px;
    z-index: 1
 }
 
 .timeline-content {
    width: 95%;
    padding: 0 15px;
    color: #666
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
 }
 
 .timeline-content span {
    font-size: 15px;
    color: #a4a4a4;
 }
 
 .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
    color: #888;
 }
 
 
 @media screen and (max-width: 768px) {
    .timline-bx:before {
       left: 8px;
       width: 2px;
    }
    .timeline-block {
       width: 100%;
       margin-bottom: 30px;
    }
    .timeline-block-right {
       float: none;
    }
 
    .timeline-block-left {
       float: none;
       direction: ltr;
    }
 }
